<template>
  <div class="recruitment-brand-manage">
    <vab-query-form>
      <vab-query-form-left-panel :span="4">
        <el-button icon="plus" type="primary" @click="selectBrandModal.open()">
          添加招聘机构
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="20">
        <el-form inline :label-width="120" @submit.prevent="query(1)">
          <el-form-item label="招聘机构名称">
            <el-input v-model="queryParams.name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item>
            <el-button icon="search" type="primary" @click="query()">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
  
    <!-- 表格 -->
    <el-table v-loading="loading" border :data="tableData" :table-layout="'auto'" :max-height="450">
      <el-table-column label="招聘机构名称" prop="name" />
      <el-table-column label="机构负责人姓名" prop="enterpriseUser" :width="160" />
      <el-table-column label="机构负责人手机号" prop="phone" :width="160" />
      <el-table-column label="操作" align="center" :width="160">
        <template #default="{ row }">
          <el-link type="danger" :underline="false" @click="del(row)">
            删除
          </el-link>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    
    <!-- 分页 -->
    <el-pagination
      background
      layout="prev, pager, next"
      v-model:current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      :total="queryParams.total"
      @current-change="query"
    />
  
    <el-dialog v-model="selectBrandModal.visible" title="添加招聘机构" :width="800" center destroy-on-close>
      <SelectBrand @change="selectBrandModal.setEnterpriseCode" />
      <template #footer>
        <el-button @click="selectBrandModal.close()">取消</el-button>
        <el-button type="primary" @click="selectBrandModal.confirm()">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, reactive, getCurrentInstance } from 'vue';
import SelectBrand from './SelectBrand.vue';
import { omit } from 'lodash';
import { queryRecruitmentBrandList, addRecruitmentBrandRef, delRecruitmentBrandRef } from '@/api/recruitment/brand';

const props = defineProps({
  visible: Boolean,
  groupId: [Number, String],
})

const emit = defineEmits(['close'])

const tableData = ref([])
const loading = ref(false)
const { proxy } = getCurrentInstance()

const defaultQueryParams = {
	name: '',
	pageNum: 1,
	pageSize: 20,
  total: 0
}

const queryParams = reactive({ ...defaultQueryParams })

function query(pageNum = queryParams.pageNum) {
  const params = {
    ...omit(queryParams, 'total'),
    pageNum,
    groupId: props.groupId,
  }
  
  loading.value = true
  
  queryRecruitmentBrandList(params).then(({code, data}) => {
    if (code !== 200) return
    const {list, total} = data;
    tableData.value = list
    queryParams.total = total
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  query()
})

function del(row) {
  proxy.$baseConfirm('确定要删除当前项吗？', null, () => {
    delRecruitmentBrandRef({
      id: row.id,
      enterpriseCode: row.enterpriseCode,
      groupId: props.groupId
    }).then(({ code }) => {
      if (code !== 200) return proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      query()
    })
  })
}

const selectBrandModal = reactive({
  visible: false,
  enterpriseCode: '',
  open() {
    this.visible = true
  },
  close() {
    this.visible = false
    this.enterpriseCode = ''
  },
  setEnterpriseCode(val) {
    selectBrandModal.enterpriseCode = val
  },
  confirm() {
    if (this.enterpriseCode) {
      addRecruitmentBrandRef({
        groupId: props.groupId,
        enterpriseCode: this.enterpriseCode
      }).then(({ code }) => {
        if (code !== 200) return proxy.$baseMessage(`操作失败`, 'error', 'vab-hey-message-error')
        this.close()
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        query(1)
        this.enterpriseCode = ''
      })
    } else {
      proxy.$baseMessage(`请选择机构`, 'warning', 'vab-hey-message-warning')
    }
  }
})
</script>

<style lang="scss" scoped></style>